import React, { useContext, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment-timezone';
//Context 
import { AuthContext } from '../../context/AuthContext';
const FeedbackListTable = (props) => {

  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");

    const handleRowClick = (rowData, rowMeta) => {
        // console.error("row data is ");
        // console.error(data[rowMeta.dataIndex]);
        // console.log(data[rowMeta.dataIndex]._id)
        // getDetailsHandle(data[rowMeta.dataIndex]._id, rowData);
        // setShowUpdateLeadModal(true);
    
        // data[rowMeta.dataIndex]._id, rowData
    
        setRowMetaData(rowMeta);
        setRowId(props.data[rowMeta.dataIndex]._id)
        // console.log("showupdate modal", showUpdateLeadModal);
      };
    const columns = [
        {
         name: "id",
         label: "Id",
         options: {
          filter: true,
          display:false,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              background: "#e6e6e6",
              fontWeight: 'bold'
            }
          })
         }
        },
        {
         name: "opened_on",
         label: "UpDated",
         options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              background: "#e6e6e6",
              fontWeight: 'bold'
            }
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
          ),
         }
        },
        {
          name: "user_name",
          label: "User",
          options: {
           filter: true,
           sort: true,
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "nowrap",
               background: "#e6e6e6",
               fontWeight: 'bold'
             }
           })
          }
         },
        {
         name: "summary",
         label: "Title",
         options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              background: "#e6e6e6",
              fontWeight: 'bold'
            }
          })
         }
        },
        {
         name: "description",
         label: "Description",
         options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              background: "#e6e6e6",
              fontWeight: 'bold'
            }
          }),
          customBodyRender: (value) => {
            const cleanedDescription = value.replace(/<\/?[^>]+(>|$)/g, '').replace(/;/g, '').replace(/&nbsp/g, '');
            return <div>{cleanedDescription}</div>;
          }
         }
        }, 
       ];
       
    
    const options = {
        filter: false,
        filterType: false,
         responsive: "standard",
         tableBodyHeight: (window.innerHeight - 180).toString() + 'px',
         tableBodyMaxHeight: (window.innerHeight - 180).toString() + 'px',
         fixedHeader: true,
         fixedSelectColumn: false,
         selectableRows: false,
         onRowClick: handleRowClick,
         download: false,
         print:  false,
         viewColumns: false,
      };
    
    return(
        <>
            <MUIDataTable
          // styles={{ styles }}
          title={"Ticket History List"}
          data={props.data}
          columns={columns}
          options={options}
        
        />

        </>
    )
}
export default FeedbackListTable;