import { useContext, useRef } from 'react';
import { loginCall } from '../../../apiCalls'
import { AuthContext } from '../../../context/AuthContext';
// import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./login.css";
import { Link } from 'react-router-dom';

export default function Login() {
  // state = {}

  const email = useRef();
  const password = useRef();
  
  const { user, isFetching, error, errorPayload, dispatch } = useContext(AuthContext);

  const handleClick = (e) => {

    e.preventDefault();

    console.log(email.current.value);
    console.log("submit button clicked");
    console.log(email.current.value);
    console.log(password.current.value);

    console.log(isFetching);

    console.log("login error is ", error?.response)

    var loginResponse = loginCall({ email: email.current.value, password: password.current.value }, dispatch);

    console.log("login Response", loginResponse);
    console.log("login Response", errorPayload?.data);

  }
  console.log(user);

  // render() {
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  console.log(user);
  return (

    <div className="login" >
      <div className="loginWrapper">
      <div className="loginLeft" style={{}}>
          <h3 className="loginLogo">KForce</h3>
          <span className="loginDesc">
          Contact Our Team for Prompt Query Resolution
          </span>
        </div>
        <div className="loginRight">
          <form className="loginBox" onSubmit={handleClick}>
            <div className="text-center" style={{paddingBottom:"1rem"}}>
              <h2>User Login</h2>
            </div>
            {/* <Link to="/register">
              <a >
                Create Account
              </a>
            </Link> */}

            <input placeholder="Email" type="email" required className="loginInput" ref={email} />
            <br/>
            <input placeholder="Password" type="password" required minLength="6" className="loginInput" ref={password} />
            <Link to="/forgot-password">
              <a className="forgotPassword">Forgot Password?</a>
            </Link>
            <div className="text-center">
              <span style={{ color: 'red' }}>{errorPayload}</span>
            </div>
            <br />
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (

                <CircularProgress color="white" size="20px" />
              ) : (
                "Log In"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>

  );
}


// import React,{ useContext, useRef } from 'react';
// // import { loginCall } from '../../../apiCalls'
// // import { AuthContext } from '../../../context/AuthContext';
// // // import { makeStyles } from '@material-ui/core/styles';
// // import CircularProgress from '@material-ui/core/CircularProgress';
// import "./login.css";
// import { Link } from 'react-router-dom';

// export default function Login() {
//  return(
//      <>
//      <h1>login</h1>
//      </>
//  )
// }