import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../home/Navbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import "./feedback.css"
import axios from "axios";
import Select from 'react-select';
// Snackbar notification
import Snackbar from '@material-ui/core/Snackbar';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// React hook use form
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
//import FeedbackListTable
import FeedbackListTable from "../../components/tables/FeedbackListTable";
//import activity ticket
import ActivityTicket from '../../components/models/ActivityTicket';
import ClosedTicket from "../../components/models/ClosedTicket";
import { Button } from 'react-bootstrap'
import ModalImage from "react-modal-image";
const Feedback = (props) => {

    const location = useLocation();

    console.log("prosp ", props);

    // snakbar
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [accountList, setAccountList] = useState([])
    const [data, setData] = useState([]);
    const [defaultTableText, setDefaultTableText] = useState('Loading...');
    const [showActivityTicketModel, setShowActivityTicketModell] = useState(false);
    const [showClosedTicketModel, setShowClosedTicketModell] = useState(false);
    const [ticketStatus, setTicketStatus] = useState("")
    const [attachedImages, setAttachedImages] = useState([]);
    const [baseUrl, setBaseUrl] = useState("");
    const [feedback, setFeedback] = useState({
        company_name: '',
        fe_first_name: '',
        fe_last_name: '',
        fe_family: '',
        fe_product_id: '',
        fe_area: '',
        fe_subarea: '',
        fe_product_version: '',
        fe_platform: '',
        fe_os: '',
        fe_summary: '',
        fe_description: '',
        fe_feedback: '',
        account_key: '',
        ticket_id: ''
    })


    const html = `<p>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
</p>`;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();

    //     const  getApplicationList = () => {
    //         setApplicationList([{ label: "Q1", value: 'Q1' },{ label: "Q2", value: 'Q2' }])
    //   }

    // const getDetailsHandle = (id) => {
    //     console.log("props ticket data here..." + id)
    //  }



    useEffect(() => {
        console.log(location.pathname); // result: '/secondpage'
        console.log(location.search); // result: '?query=abc'
        console.log(location.state); // result: 'some_value'
    }, [location]);

    // ==============get table details with api here===============

    const getDetailsHandle = async (id) => {
        try {
            console.log("table ticket id", id);
            const res = await axios.get("/tickets/details/" + id);

            const feedbackData = res.data.ticketDetails;

            setAttachedImages(feedbackData.attachments);

            setBaseUrl(res.data.ticketDetails.base_url);
            console.log("base url here", feedbackData);

            setFeedback({
                account: feedbackData.company_name,
                fe_first_name: feedbackData.first_name,
                fe_last_name: feedbackData.last_name,
                fe_family: feedbackData.family ? feedbackData.family : null,
                fe_product_id: feedbackData.product,
                fe_area: feedbackData.area,
                fe_subarea: feedbackData.sub_area,
                fe_product_version: feedbackData.product_version,
                fe_platform: feedbackData.platform,
                fe_os: feedbackData.operating_system,
                fe_summary: feedbackData.summary,
                fe_description: feedbackData.description,
                fe_feedback: feedbackData.feedback ? feedbackData.feedback : null,
                account_key: feedbackData.account,
                ticket_id: feedbackData._id ? feedbackData._id : null
            });
            setTicketStatus(feedbackData);
            setAttachedImages(feedbackData.attachments);

            setBaseUrl(res.data.ticketDetails.base_url)

            var contentBlock = htmlToDraft(feedbackData.mail_body);
            var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            var editorStateTe = EditorState.createWithContent(contentState);
            setEditorState(editorStateTe)
            console.log("feedbackdata here start.....", feedback)

            // console.log("ticket status here...", feedbackData.status)
            // setContactsList(res.data.contactsList);
            // setOpenOpportunitiesList(res.data.openOpportunitiesList);
            // setClosedOpportunitiesList(res.data.closedOpportunitiesList);
            // setAnnualRevenue(accountData.annual_revenue);
            // setAccountId({ account_id: id });

            // console.log("getting opportnity id ", id);
            // if (deleteClicked === "notClicked") {
            //   setAccountShow(true);
            // }

            if (res.data.contactsList || res.data.openOpportunitiesList.length < 1 || res.data.closedOpportunitiesList.length < 1) {
                setDefaultTableText("No Records Found");
            }


        } catch (err) {
            setSnackBarMessage("Error in fetching records...");
            setDefaultTableText('Error in fetching records');
            //  console.log("error is ", err.response.data.msg);
            setTimeout(() => {
                setOpen(false);
            }, 1000);

        }
        // console.log("res");
        // console.log(res.data.opportunityDetails);
        // console.log(res.opportunityDetails);
    };

    const onEditorStateChange = (data) => {
        console.log("editor data", data)
        setEditorState(data)
    }
    //   console.log("ticket status here...", ticketStatus.status)
    const fechData = async (id) => {
        const res = await axios.get("/tickets/details/" + id)
        setData(res.data.ticketHistory);
        // setTicketStatus(res.data.ticketHistory);
    }


    useEffect(() => {
        console.log("props receive are ", props);
        getDetailsHandle(location.state);
        if (!location.state) {
            window.location.href = "/ticket-list"
        }
        fechData(location.state);
    }, [location])

    const handleClose = (event, reason) => {
        setAnchorEl(false);
        console.log("handle close");
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };



    const onInputChange = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value }, []);
        // setSelectedFile(e.target.files[0]);
        // setIsSelected(true);
    }

    return (
        <div className="main_feedbackpage">
            <Navbar value="feedback" className="activity_list_navbar" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-12 mx-auto">
                        <div className="activity_ticket mt-4 mb-5" style={{ float: 'right', marginBottom: '5rem' }}>
                            {ticketStatus.status === "open" ?
                                <>
                                    <Button className="btn-sm navbarAddBtn btn_add_common11 mb-5" onClick={() => setShowClosedTicketModell(true)} >
                                        Close Ticket
                                    </Button>
                                    {showClosedTicketModel ? <ClosedTicket show={showClosedTicketModel} feedback={feedback} refreshCloseTicket={(id) => getDetailsHandle(id)} onHide={() => setShowClosedTicketModell(false)} /> : ""}
                                </>
                                : " "
                            }

                        </div>
                        <div className="from form_bg_main shadow">
                            <form key={1} >
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined" autoComplete="none"
                                            {...register("account", {
                                                // minLength: {
                                                //     value: 3,
                                                //     message: "account must be at least 3 characters"
                                                // }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => onInputChange(e)}
                                            value={feedback.account}
                                            helperText={errors.account ? errors.account.message : ''}
                                            error={Boolean(errors.account)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="account" name="account" label="Account" />
                                    </div>
                                    <div className="col-md-6"></div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined" autoComplete="none"
                                            {...register("fe_first_name", {
                                                // minLength: {
                                                //     value: 3,
                                                //     message: "first_name must be at least 3 characters"
                                                // }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => onInputChange(e)}
                                            value={feedback.fe_first_name}
                                            helperText={errors.fe_first_name ? errors.fe_first_name.message : ''}
                                            error={Boolean(errors.fe_first_name)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="fe_first_name" name="fe_first_name" label="First Name" />
                                    </div>

                                    <div className="col-md-6">
                                        <TextField variant="outlined" autoComplete="none"
                                            {...register("fe_last_name", {
                                                minLength: {
                                                    value: 3,
                                                    message: "last_name must be at least 3 characters"
                                                }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => onInputChange(e)}
                                            value={feedback.fe_last_name}
                                            helperText={errors.fe_last_name ? errors.fe_last_name.message : ''}
                                            error={Boolean(errors.fe_last_name)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="fe_last_name" name="fe_last_name" label="Last Name" />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined"
                                            {...register("fe_family", {
                                            })}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={feedback.fe_family}
                                            required
                                            helperText={errors.fe_family ? errors.fe_family.message : ''}
                                            error={Boolean(errors.fe_family)}
                                            onChange={e => onInputChange(e)}
                                            // required
                                            type="text" className="form-control" name="fe_family" label="Family"
                                        >
                                        </TextField>


                                    </div>
                                    <div className="col-md-6">

                                        <TextField variant="outlined"
                                            {...register("fe_product_id", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled
                                            value={feedback.fe_product_id}

                                            helperText={errors.fe_product_id ? errors.fe_product_id.message : ''}
                                            error={Boolean(errors.fe_product_id)}
                                            onChange={e => onInputChange(e)}
                                            required
                                            type="text" className="form-control" name="fe_product_id" label="Application"
                                        >

                                        </TextField>


                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined"
                                            {...register("fe_area", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={feedback.fe_area}
                                            // select
                                            disabled
                                            required
                                            helperText={errors.fe_area ? errors.fe_area.message : ''}
                                            error={Boolean(errors.fe_area)}
                                            onChange={e => onInputChange(e)}

                                            type="text" className="form-control" name="fe_area" label="Area"
                                        >

                                        </TextField>


                                    </div>
                                    <div className="col-md-6">

                                        <TextField variant="outlined"
                                            {...register("fe_subarea", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={feedback.fe_subarea}
                                            // select
                                            disabled
                                            helperText={errors.fe_subarea ? errors.fe_subarea.message : ''}
                                            error={Boolean(errors.fe_subarea)}
                                            onChange={e => onInputChange(e)}
                                            required
                                            type="text" className="form-control" name="fe_subarea" label="SubArea"
                                        >

                                        </TextField>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined"
                                            {...register("fe_product_version", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={feedback.fe_product_version}
                                            // select
                                            disabled
                                            helperText={errors.fe_product_version ? errors.fe_product_version.message : ''}
                                            error={Boolean(errors.fe_product_version)}
                                            onChange={e => onInputChange(e)}
                                            required
                                            type="text" className="form-control" name="fe_product_version" label="Product Version"
                                        >

                                        </TextField>


                                    </div>
                                    <div className="col-md-6">

                                        <TextField variant="outlined"
                                            {...register("fe_platform", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={feedback.fe_platform}
                                            // select
                                            disabled
                                            helperText={errors.fe_platform ? errors.fe_platform.message : ''}
                                            error={Boolean(errors.fe_platform)}
                                            onChange={e => onInputChange(e)}
                                            required
                                            type="text" className="form-control" name="fe_platform" label="Platform"
                                        >

                                        </TextField>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField variant="outlined"
                                            {...register("fe_os", {

                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled
                                            value={feedback.fe_os}
                                            // select
                                            helperText={errors.fe_os ? errors.fe_os.message : ''}
                                            error={Boolean(errors.fe_os)}
                                            onChange={e => onInputChange(e)}
                                            required
                                            type="text" className="form-control" name="fe_os" label="OS"
                                        >

                                        </TextField>


                                    </div>
                                    <div className="col-md-6">
                                        <TextField variant="outlined" autoComplete="none"
                                            {...register("fe_summary", {

                                                minLength: {
                                                    value: 3,
                                                    message: "summary must be at least 10 characters"
                                                }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            onChange={e => onInputChange(e)}
                                            value={feedback.fe_summary}
                                            helperText={errors.fe_summary ? errors.fe_summary.message : ''}
                                            error={Boolean(errors.fe_summary)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="fe_summary" name="summary" label="Summary" />
                                    </div>
                                </div>
                                <br />

                                <div className='row'>
                                    <div className="col-md-6">
                                        <TextField multiline rows={2}
                                            variant="outlined"
                                            {...register("fe_description", {

                                                minLength: {
                                                    value: 3,
                                                    message: "description must be at least 10 characters"
                                                }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            onChange={e => onInputChange(e)}
                                            value={feedback.fe_description}
                                            helperText={errors.fe_description ? errors.fe_description.message : ''}
                                            error={Boolean(errors.fe_description)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="fe_description" name="fe_description" label="Description" />
                                    </div>

                                    <div className="col-md-6">
                                        <TextField multiline rows={2}
                                            variant="outlined"
                                            {...register("fe_feedback", {

                                                minLength: {
                                                    value: 3,
                                                    message: "fe_feedback must be at least 10 characters"
                                                }
                                            })}
                                            disabled
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            onChange={e => onInputChange(e)}
                                            value={feedback.fe_feedback}
                                            helperText={errors.fe_feedback ? errors.fe_feedback.message : ''}
                                            error={Boolean(errors.fe_feedback)}
                                            // inputRef={designation}
                                            type="text" className="form-control" id="fe_feedback" name="fe_feedback" label="Feedback" />
                                    </div>

                                </div>
                                <br />
                                <br />
                                <div className="form-group col-md-12 editor" style={{ marginTop: '30px' }} >
                                    <label className="font-weight-bold"> Mail Body <span className="required"> * </span> </label>
                                    <div style={{ minHeight: '350px', border: '1px grey solid' }}>

                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            // onEditorStateChange={onEditorStateChange}
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </div>
                                    <textarea
                                        style={{ display: 'none' }}
                                        disabled
                                        ref={mailBody}
                                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                                    {/* <textarea style={{display:'none'}} disabled ref={(val) => userInfo.description = val} value={draftToHtml(convertToRaw(editorState.getCurrentContent())) } /> */}
                                </div>
                                <br />
                                {attachedImages && attachedImages.length > 0 && (
                                    <div className="row">
                                        <p>Attachment  Images</p>
                                        {attachedImages.map((elements, index) => {
                                            return (
                                                <div className='col-4'>
                                                    <div className="mb-2 p-0">

                                                        <ModalImage
                                                            small={`https://crm.kaizenat.com/${elements}`}
                                                            large={`https://crm.kaizenat.com/${elements}`}
                                                            alt="Attachment"
                                                            hideDownload={true}
                                                            hideZoom={true}
                                                            className="modal-image"    //Add a class name here
                                                        />

                                                        {/* <img src={`${baseUrl}/${elements}`} height="210" className='card-img' alt="img" /> */}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-11 mx-auto">
                        <div className="row">
                            <div className="col-10">
                                <div className="activity_ticket mt-5">
                                    <>
                                        <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowActivityTicketModell(true)} >
                                            Add Ticket Activity
                                        </Button>
                                        {showActivityTicketModel ? <ActivityTicket show={showActivityTicketModel} feedback={feedback} refreshFeedbackList={(id) => fechData(id)} onHide={() => setShowActivityTicketModell(false)} /> : ""}
                                    </>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="feedback_table mt-5">
                <FeedbackListTable data={data} />
            </div>
        </div>
    )
}
export default Feedback;
