import axios from "axios";
//Context 
// import { AuthContext } from '../../context/AuthContext';

export const loginCall = async (userCredential, dispatch) => {

  dispatch({ type: "LOGIN_START" });
  try {
    const res = await axios.post("/client/auth/login", userCredential);
    console.log("login fail", res?.data);
    dispatch({ type: "LOGIN_SUCCESS", payload: res?.data });
  } catch (err) {
    // console.log("login fail", err.response.data.msg);
    console.log("login failure", err)
    dispatch({ type: "LOGIN_FAILURE", payload: err?.response?.data?.msg });
  }
};

export const ForgotPasswords = async (userCredential, dispatch) => {
  try {
    const res = await axios.post("/client/auth/forgot/password", userCredential);
    return res;
    console.log(" forgot password try block ", res);
  } catch (err) {
    return err;
    console.log(" forgot password error ", err);
  }
};

// Reset Passwords exports.
export const ResetPasswords = async (userCredential, dispatch) => {
  try {
    const res = await axios.post("/client/auth/reset/password", userCredential);
    console.log("error for reset password is inside trys")
    return res;
    console.log(" forgot password try block ");
  } catch (err) {
    console.log("error for reset password is ", err.response.data)
    return err;
    console.log(" forgot password error ");
  }
};